.sidebar {
  width: 17rem;
  height: 100%;
  padding: 1rem;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar__top {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  justify-content: center;
}

.sidebar__logo {
  width: 3.5rem;
  height: 3.5rem;
  object-fit: contain;
  border-radius: 1rem;
}

.sidebar__logo-name {
  font-size: 1.2rem;
  font-weight: 600;
}

.sidebar__list {
  list-style: none;
}

.sidebar__link {
  display: inline-block;
  font-size: 1rem;
  text-decoration: none;
  padding: 0.8rem 1rem;
  display: flex;
  margin-bottom: 1rem;
  border-radius: 0.8rem;
  align-items: center;
}

.sidebar__icon {
  display: inline-block;
  font-size: 1.2rem;
}

.sidebar__name {
  margin-left: 0.5rem;
}

[data-collapse='true'] {
  width: 5.3rem;
}

[data-collapse='true'] .sidebar__name,
[data-collapse='true'] .sidebar__logo-name {
  display: none;
}

.btn {
  position: absolute;
  right: 0;
  top: 4.7rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transform: translateX(50%);
  font-size: 1.1rem;
}

input:checked ~ span:last-child {
  --tw-translate-x: 1.65rem; /* translate-x-7 */
}
